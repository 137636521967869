export const homeConstant = 'HOME';
export const featuresConstant = 'FEATURES';
export const portfolioConstant = 'PORTFOLIO';
export const resumeConstant = 'RESUME';
export const contactConstant = 'CONTACT';
export const myNameConstant = 'JESÚS AMARO';
export const welcomeConstant = 'WELCOME TO MY WORLD';
export const helloConstant = 'Hi, I’m ';
export const myNameMinConstant = 'Jesús Amaro';
export const professionConstant = 'Developer';
export const fullStackConstant = 'Full Stack Developer';
export const connectConstant = 'CONNECT WITH ME';
export const shortDescriptionConstant = `I would love to know what you have to say. Contact me and let's talk.`;
export const phoneTitleConstant = 'Phone: +584245404124';
export const emailTitleConstant = 'Email: contact@jesusamaro.dev';
export const professionalNetworksConstant = 'PROFESSIONAL NETWORKS';
export const socialMediasConstant = 'SOCIAL MEDIAS';
export const featureTitleConstant = 'FEATURES';
export const whatIDoConstant = 'What I Do';
export const frontConstant = 'Front-end Development';
export const fullConstant = 'Full Stack Development';
export const collosusConstant = 'Colossus Mobile App'
export const amblemaConstant = 'AMBLEMA Web App';
export const contrataConstant = 'Contrata Web App';
export const migracionConstant = 'Migración CEC Web App';
export const mcuConstant = 'MCU Web App';
export const backOfficeConstant = `Innpactia's Back Office Web App`;
export const innpactiaConstant = `Innpactia Web App`;
export const reintentosConstant = `Reintentos Manuales Web App`;
export const sabeConstant = `SABE Web App`;
export const covidConstant = `COVID-19 SPS Mobile App`;
export const casinoConstant = 'Casino Bonus Center';
export const backConstant = 'Back-end Development';
export const mobileConstant = 'Mobile Development';
export const frontDetailConstant = `I'm able to develop web applications using JavaScript, TypeScript, React, Next.js, Angular, HTML, CSS, Sass, Bootstrap, Material and Tailwind CSS.`;
export const backDetailConstant = 'Do you need an API service for the application? No problem, I have experience using Node.js with Express.js or NestJS, along with Mongo or SQL.';
export const mobileDetailConstant = `I love developing for mobile. I've created mobile applications using React Native, Dart/Flutter and Ionic/Angular/Cordova.`
export const visitConstant = 'VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK';
export const portfolioTitleConstant = 'My Portfolio';
export const yearsExperienceConstnat = '5+ YEARS OF EXPERIENCE';
export const resumeTitleConstant = 'My Resume';
export const contactTitleConstant = 'CONTACT';
export const contactMeConstant = 'Contact Me';
export const sendMessageConstant = 'SEND MESSAGE';
export const yourNameConstant = 'YOUR NAME';
export const yourPhoneConstant = 'PHONE NUMBER'
export const yourEmailConstant = 'EMAIL';
export const yourTextConstant = 'YOUR MESSAGE';
export const yourSubjectConstant = 'SUBJECT';
export const footerDetailConstant = `© ${new Date().getFullYear()}. All rights reserved by Jesús Amaro`;
export const descriptionConstant = `I’m a computer engineer with 5+ years of experience in web and mobile development. I’ve collaborated in worldwide companies contributing with my knowledge and learning from my coworkers. My great passions are technology, travel, video games, sports, friendship and family.`;
export const twothousandandeighteenConstant = '2018';
export const march2023Constant = 'March 2023'
export const twothousandandnineteenConstant = '2019';
export const beforeConstant = '2019-2020';
export const presentConstant = '2020-2024';
export const aprilaugustConstant = 'April 2020 - August 2020';
export const septemberaugustConstant = 'September 2020 - August 2021';
export const julyConstant = 'July 2021 - August 2022';
export const currentConstant = "August 2022 - Present";
export const octoberConstant = 'October 2021';
export const aprilConstant = 'April 2019';
export const mayConstant = 'May 2019';
export const juneConstant = 'June 2019';
export const augustConstant = 'August 2019';
export const octoberUdemyConstant = 'October 2019'
export const januaryConstant = 'January 2020';
export const FebruaryConstant = 'February 2020';
export const marchConstant = 'March 2020';
export const april2020Constant = 'April 2020';
export const may2021Constant = 'May 2021';
export const november2021Constant = 'November 2021';
export const december2021Constant = 'December 2021';
export const february2022Constant = 'February 2022';
export const marnaTitleConstant = 'Intern in Marna Garage';
export const marnaDescriptionConstant = 'Responsible for the front end in a web application for a company dedicated to mining, which was developed using Bootstrap and Angular.';
export const uclaTitleConstant = 'Development coordinator in Lisandro Alvarado Central-Western University';
export const uclaDescriptionConstant = 'Mobile application for an electronic equipment repair company developed in Node.js/Express.js, Ionic, Angular and Cordova. This application corresponds to the final project of the university career, being equivalent to the thesis of degree.';
export const binauralTitleConstant = 'Front end developer in Binaural';
export const binauralDescriptionConstant = 'Web applications for the socio-educational project AmbLeMa and for Casino Bonus Center, which were developed using Angular, Bootstrap and Nebular: UI Kit, Auth & Security.';
export const ccoTitleConstant = 'Front end developer in Conocimiento Corporativo';
export const ccoDescriptionConstant = 'Web applications for SABE, MCU, Reintentos Manuales, Migración CEC and Contrata projects, which were developed using Angular, Angular material, NgRx and PrimeNG.';
export const drTitleConstant = 'Mobile developer in Dr. Armando Velasquez Mago Sentinel Hospital';
export const drDescriptionConstant = 'Mobile application developed using Dart/Flutter, which aims to provide a clinical scale predictor of severity in adult patients with SARS-CoV-2 infection.';
export const innpactiaTitleConstant = 'Full stack developer in Innpactia';
export const msbTitleConstant = 'Full stack developer in MyStrengthBook';
export const msbDescriptionConstant = "Creation of new features and maintenance of MyStrengthBook web and mobile applications that are developed using React, Next.js, React Native, Fastlane, TalkJs, FCM messages, and Node.js/Express.js";
export const innpactiaDescriptionConstant = 'Innpactia web application, and building back offices (Process optimization and Measuring team performance using the Asana API), which are developed using Node.js/Express.js, Swagger, Jest, Redis, Nodemailer, AWS SDK, Angular, Angular material, Jasmine and NgRx.';
export const bootstrapTitleConstant = 'Bootstrap course';
export const jsTitleConstant = 'JavaScript Fundamentals course';
export const leaderTitleConstant = 'Leadership Course for Work Teams';
export const ionicTitleConstant = 'ionic 6+ course: Creating IOS, Android and PWAs applications with Angular';
export const nodeTitleConstant = 'Node.js Fundamentals Course';
export const nodeProTitleConstant = 'Node.js Course';
export const scrumTitleConstant = 'Scrum Master course + Lead Scrum and Agile Teams';
export const platziConstant = 'Platzi';
export const udemyConstant = 'Udemy';
export const uclaConstant = 'Lisandro Alvarado Central-Western University';
export const basicVueTitleConstant = 'Basic Course of Vue.js';
export const computerEngineeringConstant = 'Computer Engineering';
export const dataBaseConstant = 'Database Fundamentals Course';
export const angularConstant = 'Angular Course';
export const dockerConstant = 'Docker Fundamentals Course';
export const masterConstant = 'Master course in JavaScript Frameworks: Learn Angular, React, Vue';
export const dartConstant = 'The Complete Flutter Development Bootcamp with Dart';
export const nestConstant = 'Master NestJS - The JavaScript Node.js Framework ';
export const reactTitleConstant = 'React course: From zero to expert ( Hooks and MERN )';
export const reactNativeTitleConstant = 'React Native: Native apps for IOS and Android';
export const cssTitleConstant = 'Advanced CSS and Sass: Flexbox, Grid, Animations and More';
export const downloadConstant = 'DOWNLOAD RESUME';
export const closeConstant = 'Close';
export const succesfullEmail = 'The mail has been sent successfully';
export const errorEmail = 'An error occurred while sending the mail, please contact me directly at contact@jesusamaro.dev';